import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M195.016678,566.931274 
	C193.597015,562.425476 192.237411,558.327026 190.199646,554.467346 
	C188.702972,551.632568 187.828308,548.520325 187.959183,545.321777 
	C188.427902,533.865417 188.594727,522.360107 194.000259,511.746887 
	C195.656097,508.495789 196.845520,504.930481 197.087509,501.245361 
	C197.752167,491.122894 199.853027,481.134888 199.887482,470.960052 
	C199.898895,467.587982 200.597321,464.343658 201.461884,461.117981 
	C204.173660,451.000427 204.381592,440.746490 203.417511,430.374817 
	C202.531876,420.847137 203.993408,411.362946 205.524567,402.060059 
	C206.929718,393.522766 208.419235,385.017639 209.404968,376.411224 
	C211.347610,359.449890 216.615677,343.264526 221.305862,326.950500 
	C222.107880,324.160797 223.802124,321.898132 225.515884,319.632416 
	C230.452438,313.105896 234.710373,306.140564 237.493423,298.448151 
	C241.257187,288.045074 249.876740,285.423950 259.402649,284.018219 
	C260.711365,283.825073 262.112274,283.693787 263.390533,283.943359 
	C277.391571,286.677063 291.160034,282.733124 305.056244,282.461365 
	C309.108612,282.382080 313.131958,281.161713 316.545746,278.230621 
	C321.302124,274.146759 326.689545,271.441345 333.392914,271.962128 
	C338.753204,272.378571 342.676483,269.824707 344.671692,264.662079 
	C345.331787,262.954102 346.055450,261.268402 346.653931,259.539246 
	C349.416229,251.557999 351.997406,249.123199 360.472565,249.187683 
	C369.252838,249.254471 376.311432,246.313843 382.362701,240.124557 
	C386.161926,236.238724 391.192169,235.365921 396.288269,236.289200 
	C402.902771,237.487549 408.750092,236.649994 414.139832,232.326065 
	C418.736694,228.638229 424.355072,228.323929 429.892242,229.187210 
	C440.497803,230.840714 450.277649,230.145203 459.099487,222.841049 
	C463.861664,218.898117 470.042755,218.011200 476.211060,219.571671 
	C483.189697,221.337158 489.936676,221.535156 496.718048,218.462357 
	C500.878113,216.577332 505.492401,216.699783 510.018341,217.143997 
	C521.777344,218.298141 533.505249,219.210098 545.323853,217.378220 
	C550.430603,216.586716 555.610962,217.782547 560.648376,218.921524 
	C563.924927,219.662369 567.228821,220.500824 570.532104,219.811493 
	C578.853149,218.075027 586.765869,219.440048 594.511719,222.473999 
	C601.002869,225.016464 607.291687,224.233017 613.370972,221.258942 
	C617.717529,219.132523 622.076050,217.093399 626.669800,215.532562 
	C632.228821,213.643738 637.599548,211.236496 641.719849,206.914185 
	C649.502808,198.749573 660.019775,197.432861 670.136414,196.393204 
	C686.450745,194.716583 702.597046,196.221344 718.595276,200.810745 
	C732.068909,204.675964 739.017944,213.876068 741.776489,226.328033 
	C745.003052,240.892685 754.592346,249.934067 767.341248,253.835709 
	C776.716003,256.704742 787.728821,256.406616 797.588013,251.959305 
	C803.145203,249.452545 809.230713,249.197891 815.117859,248.262253 
	C820.010742,247.484650 823.690125,250.676117 827.000305,253.702896 
	C834.571899,260.626251 840.365417,268.704620 844.508911,278.297180 
	C849.519653,289.897736 855.962524,300.870544 861.438171,312.281738 
	C868.666626,327.345703 873.238159,343.370087 878.022583,359.330170 
	C884.348083,380.430969 892.555359,400.863739 902.005188,420.737000 
	C907.642273,432.592041 908.776917,444.833160 908.389282,457.654419 
	C907.820740,476.460938 906.493591,495.386078 909.400146,514.045410 
	C911.135559,525.186035 914.064331,536.139526 915.329712,547.425293 
	C916.171692,554.934631 916.777832,562.488831 915.760864,569.848572 
	C913.773560,584.230286 916.548035,598.127502 919.131958,611.979858 
	C923.455383,635.157898 923.937378,658.324646 920.498413,681.556335 
	C918.749084,693.374146 912.985168,703.767700 907.611816,714.295044 
	C897.198975,734.695618 880.848572,750.586365 867.228943,768.552490 
	C858.281921,780.354736 849.712402,792.451416 839.897583,803.555908 
	C831.183350,813.415161 821.538818,822.329773 810.857117,830.058655 
	C793.731567,842.450012 776.693298,854.968384 759.364319,867.069641 
	C751.483643,872.572876 743.133789,877.223022 733.972107,880.744019 
	C720.054443,886.092590 705.627808,889.134705 691.305359,892.822266 
	C683.771790,894.761963 676.247314,897.105286 669.362976,901.013000 
	C667.798767,901.900818 665.999939,902.634094 664.234253,902.864563 
	C655.374390,904.020996 647.375732,907.831970 639.046814,910.650146 
	C635.609863,911.813049 631.930420,912.626770 628.316467,912.839722 
	C603.033264,914.330139 577.793457,915.007996 552.728699,909.837952 
	C546.351868,908.522644 539.826050,910.432556 533.464722,911.690979 
	C508.266876,916.676025 482.792603,920.208130 457.215790,921.443481 
	C440.704803,922.240906 423.851562,920.668457 407.509430,917.629517 
	C399.591400,916.157104 391.456696,914.704346 383.752045,912.342163 
	C373.042908,909.058655 363.479370,903.266846 357.348633,893.303894 
	C355.664673,890.567322 353.628326,888.173218 351.519348,885.844360 
	C344.899200,878.534058 339.034119,870.740906 334.795685,861.775146 
	C332.450867,856.815063 328.629303,853.350769 323.894287,850.478333 
	C314.096649,844.534729 304.076111,838.853210 296.191284,830.212952 
	C289.893677,823.312073 280.107269,822.368469 272.860962,817.019592 
	C272.461761,816.724854 271.894928,816.613770 271.573395,816.263611 
	C261.176056,804.939270 246.841309,798.211304 236.811371,786.438660 
	C234.287750,783.476501 232.076736,780.400879 230.875259,776.700439 
	C226.717209,763.894043 222.756561,751.059021 217.851013,738.462891 
	C214.348907,729.470520 214.823410,719.001892 211.991776,709.656067 
	C209.028137,699.874573 212.875229,692.271423 216.851913,684.225098 
	C219.893234,678.071411 221.993317,671.639709 222.358719,664.642151 
	C222.511154,661.722534 223.656754,658.799011 224.667130,655.991089 
	C227.024612,649.439392 226.318085,646.476990 220.945541,641.956665 
	C218.269958,639.705505 215.428726,637.623840 212.942352,635.180664 
	C207.066025,629.406433 204.625061,622.304260 206.368561,614.227173 
	C207.609573,608.478027 206.321899,603.551758 202.658356,599.326477 
	C197.309082,593.156921 195.783554,586.154968 197.270599,578.358154 
	C198.037567,574.336792 196.880203,570.787964 195.016678,566.931274 
M540.528381,622.027954 
	C541.687500,620.831116 542.877441,619.662476 544.001221,618.433350 
	C564.679016,595.817993 568.253540,562.229919 556.657959,538.276978 
	C548.373962,521.164734 533.635437,511.617737 516.310913,505.338196 
	C489.501038,495.620605 461.769684,489.063477 434.407471,481.236328 
	C424.345520,478.358032 414.012329,476.012421 405.772339,468.847626 
	C394.000336,458.611633 392.718933,443.025696 402.652924,430.973083 
	C408.391968,424.010101 415.902405,420.012299 424.445526,417.643402 
	C437.333923,414.069672 450.535706,413.879974 463.647339,415.003754 
	C488.416656,417.126770 503.063843,429.448944 509.490234,452.679626 
	C510.405670,455.988800 512.013123,457.429535 515.516602,456.923218 
	C524.573364,455.614441 533.666260,454.553375 542.717957,453.212769 
	C555.515686,451.317413 553.131653,451.277832 550.788086,440.860718 
	C545.316406,416.539032 531.450500,399.252716 508.159546,389.754700 
	C482.338440,379.224854 455.556122,377.079590 428.216248,380.867004 
	C411.378754,383.199524 395.431702,388.327515 381.597992,398.725494 
	C358.015228,416.451233 348.382751,446.208313 357.197083,474.428040 
	C363.396790,494.276855 377.074249,507.111176 395.855225,514.979248 
	C412.027740,521.754578 428.910950,526.318726 445.800201,530.870422 
	C463.956909,535.763672 482.449860,539.565674 499.855316,546.958313 
	C515.529968,553.615845 522.228333,569.588928 516.066467,585.407776 
	C510.871429,598.744568 501.048584,607.168945 487.619812,611.433594 
	C471.222992,616.640808 454.450592,616.766357 437.711914,613.735962 
	C412.335510,609.142029 396.980530,593.485229 391.224396,568.771484 
	C389.431305,561.072937 387.391479,561.833313 381.655396,562.661682 
	C373.748230,563.803650 365.878418,565.209717 357.964233,566.296326 
	C346.782501,567.831482 346.472382,567.826294 349.251953,578.838684 
	C357.896271,613.086365 378.277191,636.315918 413.072479,645.609253 
	C435.430603,651.580872 458.219055,652.406250 480.982208,649.375732 
	C503.194244,646.418640 523.523865,638.464783 540.528381,622.027954 
M678.415649,452.951782 
	C680.884216,447.861145 683.328003,442.758301 685.825928,437.682098 
	C694.863586,419.315491 703.931274,400.963593 712.949036,382.587250 
	C713.785889,380.882050 715.445801,379.110443 713.669189,377.102386 
	C708.968445,371.789459 704.059814,366.688660 697.764526,361.577423 
	C658.361572,432.481476 619.382874,502.622101 580.431824,572.712891 
	C577.380310,571.411011 577.783875,568.178284 575.490417,567.203857 
	C573.881042,567.981140 574.101501,569.504272 574.058594,570.723572 
	C573.537109,585.522644 569.479980,599.236633 561.634583,611.810852 
	C560.294800,613.958130 561.006714,615.907959 562.008423,617.932983 
	C566.954407,627.931885 571.835632,637.962830 576.785645,647.959778 
	C577.494507,649.391418 577.983582,651.083801 580.512207,651.970093 
	C613.011902,585.874451 645.530884,519.739685 678.415649,452.951782 
M810.570801,637.884033 
	C813.848389,643.300293 816.565674,644.024048 819.367065,639.700073 
	C825.642456,630.013977 826.710754,618.756775 825.159180,607.988708 
	C824.351501,602.383972 822.197876,596.580261 821.082214,590.732178 
	C820.559204,587.990723 819.371582,585.352173 818.292603,582.746826 
	C816.973206,579.560669 814.801147,578.621155 811.500916,580.265137 
	C803.627014,584.187378 795.578186,587.819519 791.668335,596.575134 
	C791.387878,597.203369 790.366882,597.975220 789.770752,597.912598 
	C785.225586,597.435730 784.817688,600.962708 783.856506,603.880310 
	C782.295959,608.617188 780.804810,613.378235 779.370972,618.155151 
	C778.277710,621.797241 780.003967,623.547668 783.672119,622.690613 
	C784.698303,622.450745 785.271973,620.721497 786.719727,621.774780 
	C786.893555,622.230896 787.110352,622.681641 787.239563,623.156250 
	C790.344910,634.566589 793.523438,636.576660 805.153320,633.940125 
	C808.347290,633.215942 809.360413,634.904419 810.570801,637.884033 
M715.350891,339.037476 
	C713.254578,343.951599 714.272034,348.308533 718.257019,351.661377 
	C722.084839,354.881927 726.656616,354.994812 730.906738,352.679718 
	C735.430603,350.215515 736.396057,345.845978 735.725281,341.025726 
	C735.052795,336.193420 731.754639,333.715088 727.422485,332.845764 
	C722.366211,331.831085 718.302917,333.777496 715.350891,339.037476 
M481.781433,670.208191 
	C478.808960,669.960632 477.007904,670.891418 477.061493,674.305298 
	C477.192108,682.625549 477.184113,690.948120 477.220917,699.269714 
	C477.229340,701.174622 477.884216,702.630310 480.008759,702.635620 
	C484.978058,702.648010 490.008942,703.444641 494.881165,701.857849 
	C500.893707,699.899719 502.845184,693.101013 498.842743,688.167542 
	C498.030182,687.165894 497.102325,686.257874 496.173126,685.247437 
	C496.594299,684.480835 496.882141,683.895508 497.224091,683.343628 
	C501.700958,676.119019 498.747040,670.586060 490.194122,670.254150 
	C487.700745,670.157410 485.201324,670.217529 481.781433,670.208191 
M399.070801,684.425415 
	C396.670654,683.752502 394.227142,683.202087 391.882996,682.371216 
	C389.670349,681.587097 388.236572,680.017395 388.467133,677.457214 
	C388.673523,675.165771 390.152679,673.742493 392.201782,673.255737 
	C396.537231,672.225891 400.834930,671.837402 404.009216,676.038086 
	C404.774719,677.051147 405.374695,678.442993 407.440460,677.904907 
	C408.105194,674.686218 406.336609,672.677063 403.779022,671.234009 
	C400.465393,669.364319 396.843323,668.845459 393.138458,669.734253 
	C389.054443,670.713989 385.602905,672.396667 385.183411,677.366699 
	C384.744049,682.571411 387.744293,684.954224 392.128082,686.280762 
	C395.312347,687.244324 398.564392,687.992371 401.722931,689.029480 
	C404.088470,689.806152 405.847595,691.448242 405.911438,694.084167 
	C405.981079,696.957947 404.084564,698.572327 401.603729,699.601807 
	C396.919312,701.545532 391.353699,700.052917 388.130096,696.013062 
	C387.279297,694.946838 386.958618,693.043701 384.863007,693.766357 
	C384.501740,699.278015 387.183197,701.933411 394.401794,703.250977 
	C400.991699,704.453918 407.218719,701.430176 408.794098,696.262512 
	C410.581360,690.399780 408.179352,687.284973 399.070801,684.425415 
M563.639160,693.067749 
	C563.318420,696.151062 560.056458,698.787170 561.952637,702.303406 
	C564.958130,702.390930 564.528503,699.919922 565.335938,698.493774 
	C566.303040,696.785522 566.229553,694.100037 568.188416,693.426208 
	C572.170776,692.056335 576.435730,692.566650 580.537781,693.024536 
	C583.343140,693.337646 583.333496,696.416992 584.438232,698.365051 
	C585.461365,700.169312 585.366516,703.311584 589.016174,702.209412 
	C589.091614,701.684692 589.332642,701.160522 589.200317,700.759583 
	C586.015686,691.110107 581.902954,681.829773 577.892822,672.509521 
	C577.234863,670.980347 576.067261,670.207458 574.335999,670.347107 
	C572.503479,670.494934 571.869812,671.845642 571.317871,673.268738 
	C568.853210,679.624695 566.405334,685.987244 563.639160,693.067749 
M429.414581,685.375977 
	C429.247559,688.892395 429.512177,692.379761 430.476929,695.764832 
	C431.927856,700.855835 436.791199,703.833923 442.998474,703.569336 
	C449.333954,703.299255 454.232300,699.934937 454.578827,694.668640 
	C455.058716,687.376404 454.815521,680.035400 454.828461,672.715149 
	C454.829895,671.904480 454.537781,671.043274 452.949951,671.105042 
	C451.501556,672.591309 451.452942,674.740845 451.431976,676.864807 
	C451.382690,681.859558 451.462036,686.856567 451.343445,691.849304 
	C451.217560,697.148865 448.895508,699.749939 444.095886,700.264221 
	C438.292694,700.886047 434.861847,698.681091 433.722839,693.420166 
	C432.403748,687.327454 433.329163,681.135803 433.054901,674.995605 
	C432.987335,673.482727 433.961731,671.608276 431.081146,670.164429 
	C428.304626,674.724609 429.847260,679.752136 429.414581,685.375977 
M495.763245,804.919067 
	C492.110046,804.690735 488.458344,804.433716 484.803070,804.246338 
	C482.984741,804.153076 481.448395,804.530396 481.390289,806.848083 
	C481.332642,809.147217 482.800018,809.534180 484.646210,809.645569 
	C498.939148,810.507751 513.232117,811.107605 527.517456,809.574463 
	C529.483154,809.363464 531.882935,809.189880 531.532715,806.307312 
	C531.206177,803.620239 528.829346,804.244751 527.151062,804.322571 
	C517.009583,804.793030 506.874176,805.404358 495.763245,804.919067 
M722.754883,312.787170 
	C737.920959,311.472748 749.667603,320.602631 754.224304,331.841736 
	C759.403931,344.617310 754.859009,360.777557 743.620789,368.387360 
	C743.018799,368.795013 742.169739,369.260010 742.429749,370.306641 
	C753.751343,366.761261 761.056152,347.833649 756.520874,333.827728 
	C750.744263,315.988220 731.641113,306.362762 714.176453,312.491730 
	C700.971802,317.125671 689.910400,333.707947 693.830261,344.598938 
	C696.037598,326.954407 704.313232,315.613953 722.754883,312.787170 
M527.881653,694.682800 
	C528.197083,695.451111 528.468079,696.241516 528.836853,696.983337 
	C529.767395,698.855408 530.062134,701.547729 532.705078,701.666138 
	C535.435669,701.788574 535.873535,699.163330 536.716431,697.233154 
	C539.170898,691.612488 541.936035,686.093872 543.892334,680.303162 
	C544.886047,677.361694 547.690247,674.456909 545.399963,669.640747 
	C539.489624,679.129395 538.213074,689.676880 532.684875,699.599487 
	C529.648071,690.814331 527.151672,683.289490 524.405151,675.857056 
	C523.708191,673.971191 523.414917,671.096619 519.335266,671.637329 
	C521.422363,679.535950 524.813171,686.652832 527.881653,694.682800 
M741.146790,331.483429 
	C749.191162,343.590973 745.430176,353.499725 734.973938,362.161438 
	C745.678894,357.417572 749.981934,344.726013 744.900757,334.273773 
	C739.714905,323.606140 726.488464,318.863800 715.996094,323.910065 
	C707.479370,328.006134 701.880066,338.131775 704.818420,344.123322 
	C711.224304,323.361481 727.220276,317.472656 741.146790,331.483429 
M793.410400,561.054260 
	C790.722839,567.740662 791.246826,575.035645 789.472900,581.927551 
	C788.642151,585.154968 791.539429,585.383423 794.214478,585.299255 
	C796.918945,578.649536 796.681885,571.552490 798.227844,564.825684 
	C799.093506,561.059265 797.581177,559.856262 793.410400,561.054260 
M767.790955,658.020325 
	C771.687500,652.375366 774.163940,645.990417 777.220703,639.908203 
	C778.107056,638.144470 778.639954,636.515442 776.183289,635.403503 
	C773.622437,634.244324 772.515015,635.665405 771.570007,637.642517 
	C769.133972,642.739197 766.728455,647.850952 764.233826,652.918823 
	C762.605530,656.226746 763.593689,657.964600 767.790955,658.020325 
M771.008301,421.614655 
	C772.192871,424.345917 773.333984,427.097168 774.577332,429.801392 
	C775.408020,431.608063 776.894104,432.095612 778.703247,431.360809 
	C780.733521,430.536194 780.747131,429.080658 779.918640,427.305359 
	C777.401733,421.911896 774.983582,416.472076 772.441895,411.090515 
	C770.713684,407.431396 768.497742,407.814911 765.765991,410.617432 
	C767.426453,414.107483 769.051392,417.522797 771.008301,421.614655 
M217.134048,485.767212 
	C216.314682,491.502960 215.504700,497.240051 214.670197,502.973602 
	C214.419434,504.696564 214.135040,506.404846 216.583939,506.709351 
	C218.513748,506.949371 219.973770,506.184570 220.259430,504.233856 
	C221.170288,498.014130 221.950699,491.774902 222.733551,485.537140 
	C222.980316,483.570862 222.064682,482.439545 220.061874,482.069916 
	C217.555374,481.607330 217.325806,483.206482 217.134048,485.767212 
M694.394470,745.819885 
	C698.840515,742.070862 703.307129,738.345764 707.718567,734.556458 
	C708.857239,733.578491 710.044189,732.436707 708.648132,730.779236 
	C707.404724,729.302856 705.882385,728.801880 704.297180,730.110596 
	C698.714417,734.719421 693.190918,739.400024 687.758301,743.958313 
	C689.348694,747.430542 691.140442,748.410034 694.394470,745.819885 
M222.306351,461.610687 
	C222.141464,462.081573 221.931992,462.542084 221.819412,463.025146 
	C221.335373,465.102234 220.292648,467.541412 223.323715,468.415558 
	C226.519058,469.337067 226.672607,466.511932 227.272018,464.543976 
	C228.867203,459.306885 230.297104,454.018311 231.957596,448.802734 
	C232.542191,446.966522 232.682236,445.605896 230.643082,444.718140 
	C228.245438,443.674286 227.205231,445.075073 226.613861,446.999420 
	C225.200989,451.596985 223.895020,456.227448 222.306351,461.610687 
M447.478760,261.742920 
	C451.052124,261.047150 454.623535,260.341095 458.199707,259.660004 
	C459.938660,259.328827 460.808258,258.327881 460.683319,256.545013 
	C460.544617,254.565170 459.162109,253.977859 457.551727,254.259750 
	C451.202454,255.371155 444.867889,256.573608 438.549469,257.849304 
	C436.861908,258.190002 436.032623,259.408051 436.516022,261.200531 
	C436.972504,262.893097 438.066345,263.695038 439.854034,263.324158 
	C442.121674,262.853699 444.384644,262.360840 447.478760,261.742920 
M227.586945,616.911438 
	C227.879333,617.690918 228.105225,618.503906 228.478088,619.242737 
	C229.233261,620.739258 229.241974,623.234192 232.017151,622.323547 
	C234.846054,621.395264 234.581726,619.472107 233.762451,617.115601 
	C231.905991,611.775757 230.260117,606.362305 228.560715,600.968628 
	C227.985626,599.143372 226.983734,598.058655 224.911804,598.502869 
	C222.615158,598.995117 222.623398,600.522156 223.173004,602.324219 
	C224.579346,606.935425 225.968201,611.552002 227.586945,616.911438 
M749.372009,677.816772 
	C747.340576,680.833923 745.297302,683.843201 743.282898,686.871704 
	C742.343689,688.283691 742.193726,689.604980 743.802185,690.724609 
	C745.281250,691.754150 746.686035,691.700317 747.702515,690.236389 
	C751.379395,684.940735 754.996155,679.602356 758.565491,674.233582 
	C759.501465,672.825867 759.416199,671.514404 757.763489,670.443848 
	C755.921570,669.250793 754.700317,669.825989 753.628845,671.443298 
	C752.350342,673.372986 751.062317,675.296265 749.372009,677.816772 
M305.029175,334.464905 
	C310.222473,329.903687 315.415802,325.342438 320.545868,320.836761 
	C319.165955,316.831116 317.213470,316.124146 314.279663,318.779205 
	C309.992340,322.659241 305.647705,326.476746 301.405334,330.404968 
	C300.395844,331.339722 298.830536,332.390991 300.144409,334.131714 
	C301.293457,335.654053 302.778259,336.053589 305.029175,334.464905 
M498.558502,250.689331 
	C491.712250,249.489532 484.993774,251.487122 478.193207,251.536148 
	C476.593170,251.547684 475.319000,252.475616 475.376770,254.376465 
	C475.447510,256.703156 477.120300,256.990784 478.882629,256.894318 
	C484.859985,256.566986 490.837433,256.220703 496.806366,255.769348 
	C499.888397,255.536316 500.232452,253.674911 498.558502,250.689331 
M666.811646,765.309937 
	C670.117737,763.112671 673.436279,760.933655 676.723816,758.708923 
	C678.018250,757.832825 679.178650,756.821594 677.934204,755.075928 
	C676.992310,753.754517 675.797791,752.790405 674.174377,753.813904 
	C668.579712,757.341309 663.010315,760.910828 657.494141,764.559387 
	C655.954529,765.577637 656.148682,767.046143 657.117615,768.474060 
	C658.128113,769.962891 659.377258,769.563660 660.596008,768.898621 
	C662.482971,767.869080 664.325745,766.758545 666.811646,765.309937 
M595.935303,797.399780 
	C599.241943,796.302612 602.556824,795.229248 605.851868,794.098206 
	C607.523254,793.524536 608.950195,792.745178 608.214417,790.509827 
	C607.549622,788.490356 606.157349,788.040161 604.306458,788.614319 
	C598.444153,790.432678 592.591248,792.281555 586.737854,794.128906 
	C585.128052,794.636963 584.097717,795.586304 584.579956,797.435730 
	C585.115356,799.488892 586.646057,799.927612 588.459900,799.494812 
	C590.716797,798.956177 592.934509,798.253540 595.935303,797.399780 
M213.291855,530.566650 
	C213.348145,534.723511 213.357895,538.881714 213.485855,543.036377 
	C213.530899,544.498840 214.273605,545.577332 215.931320,545.640869 
	C217.752563,545.710693 218.851715,544.778931 218.858780,543.002319 
	C218.884567,536.518311 218.838608,530.033264 218.732361,523.549988 
	C218.705841,521.930115 217.553421,521.304260 216.015076,521.283875 
	C214.413528,521.262573 213.473694,521.997192 213.403656,523.614685 
	C213.317368,525.607666 213.321152,527.604614 213.291855,530.566650 
M265.451843,375.161743 
	C263.409210,373.920776 262.361603,375.391571 261.451385,376.768463 
	C258.265808,381.587219 255.099243,386.421234 252.066635,391.336578 
	C251.201431,392.738922 249.646515,394.463379 252.218063,395.920898 
	C254.420883,397.169434 255.586258,395.834778 256.653870,394.219757 
	C259.930969,389.262390 263.212677,384.307983 266.462738,379.332916 
	C267.361633,377.956909 267.647980,376.576691 265.451843,375.161743 
M723.070679,719.463318 
	C727.090271,715.063232 731.100891,710.654907 735.134644,706.267883 
	C736.424255,704.865234 736.895386,703.622559 735.234314,702.045715 
	C733.574707,700.470215 732.336304,700.937805 731.050842,702.381348 
	C726.334229,707.677612 721.618286,712.974304 716.830811,718.350891 
	C718.353271,720.961792 719.857910,722.679016 723.070679,719.463318 
M514.442749,251.834381 
	C514.144104,253.618683 514.198364,255.343765 516.462646,255.620422 
	C522.891235,256.405914 529.338684,257.024872 535.829163,256.890900 
	C537.280334,256.860992 538.443909,256.329742 538.625183,254.663345 
	C538.803406,253.025635 537.820679,251.921768 536.429138,251.853500 
	C529.229614,251.500336 522.086182,249.160126 514.442749,251.834381 
M555.298584,806.686707 
	C559.679565,805.791382 564.065979,804.921143 568.438965,803.988464 
	C570.207947,803.611267 570.882446,802.433167 570.536499,800.661255 
	C570.272156,799.306763 569.426147,798.537170 568.085693,798.764465 
	C561.384521,799.901367 554.690613,801.084473 548.009033,802.330933 
	C546.377380,802.635376 546.083557,803.955566 546.264343,805.419128 
	C546.468140,807.069397 547.520325,807.715088 549.040710,807.558472 
	C550.851074,807.371948 552.645813,807.033264 555.298584,806.686707 
M342.635498,298.178558 
	C338.766571,300.779755 334.883942,303.360962 331.036713,305.993866 
	C329.809601,306.833618 329.148743,307.946747 330.266693,309.348236 
	C331.219086,310.542206 332.260895,311.844360 333.995941,310.683594 
	C340.162872,306.557648 346.292969,302.376648 352.397797,298.243073 
	C349.682892,293.774414 349.682892,293.774414 342.635498,298.178558 
M244.272827,417.862396 
	C245.047302,416.212799 245.862595,414.580872 246.585449,412.908966 
	C247.400879,411.022949 247.371277,409.393677 245.161087,408.373688 
	C242.821259,407.293854 242.194733,409.093964 241.517654,410.536682 
	C238.984283,415.934937 236.558899,421.383789 234.068863,426.802582 
	C233.316559,428.439728 232.828522,429.916168 234.980743,430.944122 
	C237.305130,432.054321 238.291870,430.786469 239.133270,428.896271 
	C240.682648,425.415649 242.316772,421.972748 244.272827,417.862396 
M785.281006,461.245270 
	C786.111145,463.536713 786.479431,466.026611 788.064514,468.060394 
	C789.507019,467.473541 791.171509,467.830200 792.409729,466.154083 
	C791.225037,459.684235 789.221680,453.236908 786.950317,446.855499 
	C786.266052,444.933105 785.221191,444.197754 783.183655,445.003601 
	C781.078674,445.836121 781.138184,447.348846 781.656067,449.072815 
	C782.799011,452.878326 783.966309,456.676544 785.281006,461.245270 
M284.660675,704.893982 
	C284.223175,704.392151 283.790375,703.886169 283.347321,703.389343 
	C282.033875,701.916443 280.896667,699.928711 278.511780,701.951050 
	C276.158661,703.946472 277.792999,705.553284 279.082336,707.013550 
	C282.716461,711.129456 286.413239,715.192932 290.180664,719.187195 
	C292.430206,721.572205 294.461456,721.248840 296.184204,718.180664 
	C293.154846,713.330933 288.568604,709.854553 284.660675,704.893982 
M266.001038,690.259766 
	C267.329651,691.702026 268.583893,691.394287 269.817139,690.115295 
	C271.195099,688.686096 270.443237,687.385498 269.586609,686.099792 
	C266.370392,681.272705 263.114441,676.471741 259.934906,671.620667 
	C257.747101,668.282715 255.854630,669.294495 253.482254,672.126099 
	C257.529358,678.052490 261.531891,683.913635 266.001038,690.259766 
M237.325836,641.361877 
	C239.592712,645.974487 241.947876,650.546448 244.098434,655.212646 
	C245.814697,658.936462 247.784149,658.909668 250.535370,655.864563 
	C247.746292,650.049072 245.006927,644.220703 242.150589,638.450256 
	C241.257660,636.646362 240.708969,634.049561 237.574234,635.421875 
	C234.199921,636.899109 236.380264,638.863464 237.325836,641.361877 
M218.142044,581.148010 
	C218.491104,582.751038 218.527664,584.789734 220.997543,584.275391 
	C223.140045,583.829224 223.952011,582.173157 223.633575,580.111145 
	C222.771561,574.529358 221.774506,568.968445 220.913986,563.386475 
	C220.583801,561.244629 219.688614,559.917175 217.333054,560.326416 
	C214.672272,560.788757 215.070114,562.871155 215.341705,564.643372 
	C216.146774,569.896606 217.083801,575.129517 218.142044,581.148010 
M562.363708,260.983429 
	C566.285339,261.627045 570.194824,262.365082 574.134277,262.869385 
	C575.790894,263.081482 577.027405,262.091248 577.361877,260.389771 
	C577.656555,258.891205 576.557373,258.363678 575.365662,258.039703 
	C569.114685,256.340240 562.769226,255.104507 556.346985,254.278931 
	C554.634949,254.058823 553.420227,254.709961 553.243774,256.629333 
	C553.079346,258.418457 553.838074,259.451172 555.652161,259.745728 
	C557.616943,260.064850 559.568237,260.467194 562.363708,260.983429 
M633.317078,776.932556 
	C630.163879,778.402344 626.984558,779.819641 623.869568,781.366455 
	C622.529907,782.031738 621.110779,782.914673 621.972412,784.807434 
	C622.692688,786.389465 624.001770,786.915527 625.679688,786.360718 
	C631.842957,784.323120 637.547852,781.273438 643.275818,778.291565 
	C644.921021,777.435120 644.858459,775.986145 644.042358,774.440796 
	C643.246826,772.934570 642.087585,772.751160 640.710876,773.375305 
	C638.447388,774.401550 636.220154,775.507874 633.317078,776.932556 
M283.315704,348.840942 
	C280.531647,352.126434 277.698578,355.372223 274.986755,358.716278 
	C273.840302,360.130035 271.626526,361.568359 274.246155,363.611359 
	C276.811462,365.612030 278.081421,363.575623 279.489563,361.917328 
	C282.280579,358.630432 285.307648,355.515656 287.813171,352.025360 
	C289.280029,349.981964 293.983887,348.048279 290.137054,344.909302 
	C286.813049,342.196930 285.869720,347.029724 283.315704,348.840942 
M378.712036,278.288605 
	C373.423096,280.932465 368.134125,283.576324 362.598206,286.343658 
	C363.660248,288.761444 363.905701,291.557587 367.007477,290.166809 
	C373.037079,287.463165 378.913910,284.416199 384.828156,281.459656 
	C386.247772,280.750000 386.519257,279.574127 385.796417,278.121246 
	C385.102142,276.725739 384.073547,276.121887 382.578278,276.634338 
	C381.486603,277.008484 380.445862,277.531281 378.712036,278.288605 
M633.895935,277.757965 
	C631.859741,277.229370 629.476501,274.897858 628.108643,278.486237 
	C626.919312,281.606262 629.894470,281.971558 631.602295,282.859772 
	C636.159973,285.230133 640.836487,287.371521 645.456970,289.621948 
	C647.197083,290.469452 648.957336,291.187836 650.154541,288.940277 
	C651.436096,286.534546 649.430359,285.608246 647.883850,284.790619 
	C643.488159,282.466614 639.007263,280.303864 633.895935,277.757965 
M315.812836,735.710388 
	C313.056427,733.393494 310.299988,731.076599 307.569061,728.781128 
	C304.214752,730.569641 303.492462,732.442688 306.452667,734.912415 
	C310.892273,738.616394 315.299286,742.359497 319.733185,746.070374 
	C321.079926,747.197449 322.488251,748.131836 323.944031,746.216736 
	C325.225983,744.530457 324.615265,743.099609 323.205780,741.892090 
	C320.946472,739.956421 318.638214,738.077942 315.812836,735.710388 
M399.022034,270.635223 
	C399.320587,273.296814 400.105347,275.222626 403.448792,274.049835 
	C408.775818,272.181335 414.153473,270.457214 419.480225,268.587982 
	C421.232849,267.972961 423.153900,267.103424 422.300415,264.715942 
	C421.559448,262.643372 419.572632,262.962036 418.014374,263.440826 
	C411.686371,265.385101 405.210449,266.935455 399.022034,270.635223 
M345.318848,764.143799 
	C347.581543,765.537109 349.826172,766.960876 352.113373,768.312744 
	C353.590393,769.185852 355.076660,769.969971 356.374207,767.913269 
	C357.578522,766.004333 356.763245,764.848511 355.148071,763.831848 
	C349.399933,760.213562 343.662476,756.578186 337.987366,752.991211 
	C336.195618,753.875916 335.101471,754.973511 334.996552,757.392700 
	C338.096558,759.430359 341.396942,761.599731 345.318848,764.143799 
M663.251282,295.810852 
	C661.943909,297.347839 661.856689,298.443207 663.846497,299.734314 
	C668.851318,302.981720 673.703430,306.467407 678.572937,309.919312 
	C681.360535,311.895416 683.080811,311.053284 684.390930,307.738617 
	C679.227417,303.321075 673.533508,299.692688 667.665039,296.306824 
	C666.408203,295.581665 665.286743,294.339050 663.251282,295.810852 
M373.681519,773.932129 
	C371.754395,772.963806 370.153900,773.071106 369.153137,775.269897 
	C368.098206,777.587769 369.907806,778.223694 371.380280,778.902466 
	C376.520264,781.271484 381.714508,783.522766 386.855530,785.889587 
	C389.983582,787.329590 391.440277,786.219482 391.987671,782.617737 
	C386.036469,779.790771 380.191681,777.014343 373.681519,773.932129 
M621.388000,688.392578 
	C623.584778,689.639587 626.988098,689.777954 627.194519,693.000183 
	C627.355347,695.510986 625.988770,697.705444 623.480347,699.009094 
	C618.947754,701.364807 613.061218,700.520996 609.174011,696.868347 
	C608.170410,695.925354 607.766541,694.116211 605.851135,694.337708 
	C605.448975,697.784485 605.448975,697.784485 606.687073,699.318054 
	C610.676636,704.259766 621.695740,705.028992 626.262695,700.684448 
	C629.273010,697.820679 629.536865,694.218994 628.604309,690.541321 
	C627.828308,687.481384 625.220398,686.069824 622.354431,685.505371 
	C620.266785,685.094238 618.721741,685.610474 621.388000,688.392578 
M445.657288,804.287842 
	C451.214081,805.273804 456.700073,806.722534 462.373779,806.945129 
	C464.175018,807.015869 466.317871,807.320984 466.635712,804.720581 
	C466.922577,802.374084 464.900543,802.095642 463.331360,801.812866 
	C457.773285,800.811218 452.185608,799.974976 446.617157,799.028870 
	C444.982910,798.751221 443.678070,798.815002 443.053589,800.719910 
	C442.424225,802.639526 443.293335,803.530334 445.657288,804.287842 
M409.187714,794.484070 
	C413.927368,795.920532 418.636322,797.473572 423.422150,798.734863 
	C425.276672,799.223694 427.724213,799.565735 428.354034,796.921936 
	C428.973511,794.321411 426.451324,794.175171 424.869629,793.653320 
	C420.009338,792.049866 415.091980,790.619446 410.196686,789.122070 
	C408.306671,788.543884 406.280975,787.514771 405.291748,790.374207 
	C404.243988,793.402954 406.818695,793.451904 409.187714,794.484070 
M612.733398,269.346436 
	C607.169617,266.718384 601.294617,264.982330 595.376709,263.399902 
	C593.966187,263.022705 592.208801,262.765228 591.572266,264.656311 
	C590.953979,266.493256 592.286377,267.414886 593.772766,267.958435 
	C600.251587,270.327606 606.748230,272.647919 613.435608,275.056976 
	C614.482056,272.772614 615.271667,271.189636 612.733398,269.346436 
M799.928162,540.129639 
	C800.785828,534.843506 800.963379,529.540833 800.206055,524.231323 
	C799.978027,522.632263 799.289124,521.375000 797.338806,521.439453 
	C795.822693,521.489624 794.634460,522.025879 794.622070,523.692017 
	C794.569885,530.735046 794.599976,537.778809 794.599976,545.427490 
	C798.179199,544.375305 799.512268,543.152161 799.928162,540.129639 
M759.244446,387.213226 
	C757.752197,385.007202 756.273315,382.791931 754.764465,380.597382 
	C752.874023,377.848053 750.947510,377.827393 748.861145,380.506592 
	C752.003784,386.436829 754.700256,392.481293 759.072144,397.442444 
	C763.839478,395.108032 763.867615,395.045563 761.682861,391.245544 
	C761.019043,390.090942 760.314209,388.959869 759.244446,387.213226 
M793.866882,497.665894 
	C797.363708,498.068329 796.785461,499.553406 794.939941,501.500427 
	C793.779907,502.724304 793.708923,504.226807 794.515198,505.651337 
	C795.073975,506.638611 796.184021,506.817596 797.157166,506.486389 
	C799.070435,505.835205 798.745361,504.074036 798.591553,502.671600 
	C797.976196,497.061096 797.273987,491.458099 796.466248,485.872192 
	C796.265503,484.483459 796.028076,482.676575 793.883240,482.878448 
	C791.847534,483.070068 791.114319,484.358734 791.184509,486.334106 
	C791.320557,490.166687 791.694275,493.938049 793.866882,497.665894 
M616.093323,685.199097 
	C615.520996,684.039673 614.316650,683.792542 613.309143,683.249573 
	C610.812866,681.904053 607.795532,680.639038 608.749390,677.078918 
	C609.651550,673.711731 612.720276,672.952698 615.838379,672.695312 
	C618.963684,672.437256 622.010620,672.792297 625.477234,674.714783 
	C621.365967,667.865662 611.240112,667.841248 607.008423,673.819641 
	C604.253479,677.711670 605.175537,682.311218 609.438721,684.316956 
	C611.427856,685.252869 613.588196,686.436951 616.093323,685.199097 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M483.474518,687.183350 
	C486.711945,687.225159 489.562592,687.039429 492.306549,687.495483 
	C495.297485,687.992615 497.337952,689.686523 497.390991,693.201660 
	C497.445404,696.805603 495.722046,698.870667 492.398621,699.544189 
	C490.313934,699.966614 488.127991,700.005188 485.987488,700.005310 
	C483.586029,700.005371 480.283600,700.585510 480.210541,697.066833 
	C480.140259,693.681702 477.798615,689.230286 483.474518,687.183350 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M487.785950,672.962341 
	C489.692566,673.223083 491.234894,673.271423 492.607910,673.778442 
	C494.828857,674.598694 496.008820,676.462646 496.148224,678.772827 
	C496.314392,681.526489 494.593048,683.413513 492.163574,683.757446 
	C488.364655,684.295349 483.885376,686.022949 480.898193,682.734070 
	C478.895782,680.529419 479.825226,676.783813 481.300262,674.226746 
	C482.464630,672.208252 485.241730,673.121887 487.785950,672.962341 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M570.786377,683.788574 
	C572.272888,681.013306 572.436279,678.009216 574.845154,675.227539 
	C577.638306,679.590088 578.996704,683.991760 580.320129,688.633667 
	C576.559937,690.500977 573.265625,690.163208 570.052673,689.142090 
	C568.991882,687.119080 570.380798,685.719177 570.786377,683.788574 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
